import { getCookie } from "@/common/utils/cookie"
import { createRouter, createWebHistory } from "vue-router"
import Login from "../views/Login/index.vue"
import Page404 from "../views/Page404.vue"
import Logout from "../views/logout.vue"
import ResetPwd from "../views/resetPwd.vue"
const MicroWeb = () => import("../views/micro/index.vue")
const routes = [
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/login",
		name: "login",
		component: Login,
	},
	{
		path: "/home",
		name: "home",
		component: () => import("@/views/Home/Home.vue"),
		children: [
			{
				path: "/overview",
				name: "overview",
				component: () => import("../views/overview/index.vue"),
			},
			{
				path: "/merchantManage",
				name: "merchantManage",
				component: () => import("../views/Merchant/Merchant.vue"),
			},
			{
				path: "/merchantManage/addMerchant",
				name: "addMerchant",
				component: () => import("../views/Merchant/AddMerchant.vue"),
			},
			{
				path: "/addApplication",
				name: "addApplication",
				component: () => import("../views/Application/AddApplication.vue"),
			},
			{
				path: "/applicationList",
				name: "applicationList",
				component: () => import("../views/Application/ApplicationList.vue"),
			},
			{
				path: "/applicationList/saveApplication",
				name: "saveApplication",
				component: () => import("../views/Application/SaveApplication.vue"),
			},
			{
				path: "/applicationList/createProduct",
				name: "createProduct",
				component: () => import("../views/Application/CreateProduct.vue"),
			},
			{
				path: "/applicationList/AddAppTemplate",
				name: "AddAppTemplate",
				component: () => import("../views/Application/AddAppTemplate.vue"),
			},
			{
				path: "/accountManage",
				name: "accountManage",
				component: () => import("../views/accountManage/accountManage.vue"),
			},
			{
				path: "/accountManage/addAccountManage",
				name: "addAccountManage",
				component: () => import("../views/accountManage/addAccountManage.vue"),
			},
			{
				path: "/accountManage/authAccount",
				name: "authAccount",
				component: () => import("../views/accountManage/authAccount.vue"),
			},
			{
				path: "/organization",
				name: "organization",
				component: () => import("../views/organization/organization.vue"),
			},
			{
				path: "/appAuth",
				name: "appAuth",
				component: () => import("../views/authManage/appAuth/appAuth.vue"),
			},
			{
				path: "/authSystem",
				name: "authSystem",
				component: () => import("../views/authManage/authSystem/authSystem.vue"),
			},
			{
				path: "/authManage/roleManage",
				name: "roleManage",
				component: () => import("../views/authManage/roleManage/roleManage.vue"),
			},
			{
				path: "/authManage/oauthDetail",
				name: "oauthDetail",
				component: () => import("../views/authManage/oauthDetail/oauthDetail.vue"),
			},
			{
				path: "/authManage/resource",
				name: "resource",
				component: () => import("../views/authManage/resource/resource.vue"),
			},
			{
				path: "/setting",
				name: "setting",
				component: () => import("../views/Password/Password.vue"),
			},
			{
				path: "/oauth",
				name: "oauth",
				component: () => import("../views/Oauth/Oauth.vue"),
			},
			{
				path: "/oauth/tmsAccount",
				name: "tmsAccount",
				component: () => import("../views/Oauth/tmsAccount.vue"),
			},
			{
				path: "/workorder_web",
				name: "workorder_web",
				component: MicroWeb,
			},
			{
				path: "/workorder_web/:afterUser(.*)",
				name: "workorder_web/",
				component: MicroWeb,
			},
			{
				path: "/permission_web",
				name: "permission_web",
				component: MicroWeb,
			},
			{
				path: "/permission_web/:afterUser(.*)",
				name: "permission_web/",
				component: MicroWeb,
			},
			{
				path: "/message/releaseNote",
				name: "releaseNote",
				component: () => import("../views/message/releaseNote"),
			},
		],
	},
	{
		path: "/logout",
		name: "logout",
		component: Logout,
	},
	{
		path: "/Page404",
		name: "Page404",
		component: Page404,
	},
	{
		path: "/resetPwd",
		name: "resetPwd",
		component: ResetPwd,
	},
	{
		path: "/:catchAll(.*)",
		redirect: "/Page404",
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.beforeEach((to, from, next) => {
	const auth = getCookie("Authorization")
	const d_session_id = getCookie("d_session_id")
	const hasNoAuth = () =>
		!auth ||
		auth === "undefined" ||
		auth === "null" ||
		!d_session_id ||
		d_session_id === "undefined" ||
		d_session_id === "null"
	if (to.name === "logout") {
		//退出登录
		next()
		return
	}
	if (!to.query.code) {
		if (to.name === "login") {
			if (hasNoAuth()) {
				next()
			} else {
				next({ name: "overview" })
			}
		} else if (hasNoAuth()) {
			localStorage.setItem("pageCacheFullPath", to.fullPath)
			next({ name: "login" })
			return
		}
	}
	next()
})

export default router
