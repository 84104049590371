<template>
  <div class="micro-web"></div>
</template>

<script>
import { start } from "qiankun"
export default {
	name: "mricoWeb",
	mounted() {
		if (!window.qiankunStarted) {
			console.log("子应用单独加载")
			window.qiankunStarted = true
			start({
				prefetch: false,
			})
		}
	},
}
</script>
