import { localGet } from "@/common/utils"
import { deleteCookie, getCookie } from "@/common/utils/cookie"
import { message } from "ant-design-vue"
import axios from "axios"
import JSONBig from "json-bigint"
import { getDateStr } from "./getDateStr"
axios.defaults.baseURL = "/"
// 超时时间（ms）
axios.defaults.timeout = 2000 * 1000
axios.defaults.withCredentials = true
axios.defaults.headers.post["Content-Type"] = "application/jsson"
axios.defaults.transformResponse = [
	(data) => {
		return JSONBig.parse(data)
	},
]
// 请求拦截
axios.interceptors.request.use(
	(config) => {
		const token = getCookie("d_session_id")
		const Authorization = getCookie("Authorization")
		config.headers["D-SESSION-ID"] = token
		config.headers.Authorization = Authorization
		// 可在这里做一些数据的校验。
		// path
		let path = config.url
		if (path.startsWith("null")) {
			path = path.replace("null", localStorage.getItem("apiURL"))
		}
		const requestId = guid()
		if (path.indexOf("?") > 0) {
			path = `${path}&requestId=${requestId}`
		} else {
			path = `${path}?requestId=${requestId}`
		}
		config.url = path
		// session的校验等。
		return config
	},
	(error) => {
		return error
	}
)
// 响应拦截
axios.interceptors.response.use(
	(result) => {
		const data = result.data
		if (String(data.code) !== "1000000") {
			console.log(data)
			if (data.msg) {
				message.error(data.msg)
			}
			return Promise.reject(data.result)
		}
		return Promise.resolve(data.result)
	},
	(err) => {
		console.log(JSON.stringify(err))
		const errList = ["Unexpected '<'", "Network Error"]
		const data = err?.response?.data
		if (!err.response && errList.includes(err.message)) {
			message.destroy()
			message.error("网络异常，请求超时")
			return Promise.reject(data)
		}
		if (!data?.config?.url.includes("logout") && data?.subCode === "sys.user-error-unlogin") {
			deleteCookie(() => {
				localStorage.clear()
				window.location.href = "/login"
			})
		}
		message.error(data?.msg)
		return Promise.reject(data)
	}
)

function guid() {
	const time = getDateStr(0, true).replace(/\D/g, "")
	const userAccount = localGet("userAccount") || "xxx"
	const s4 = () =>
		Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	return `rqname${userAccount}${time}${s4()}`
}

export default axios
