<template>
  <div>
    <a-card title="店铺-账号信息" class="cardMiniBox" size="small">
      <p class="cardContent">管理店铺-账号信息</p>
      <p class="cardP">
        <a type="text" style="color: #1890ff" @click="loginLazada">登录</a>
        <a type="text" style="color: #1890ff" @click="openAccountModal">
          设置账号
        </a>
      </p>
    </a-card>
    <a-modal
      v-model:visible="visible"
      title="设置店铺用户名密码"
      okText="确定"
      cancelText="取消"
      @ok="handleOk"
    >
      <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        autocomplete="off"
      >
        <a-form-item
          label="用户名"
          name="account"
          :rules="[{ required: true, message: 'Please input your username!' }]"
        >
          <a-input v-model:value="formState.account" />
        </a-form-item>
        <a-form-item
          label="密码"
          name="password"
          :rules="[{ required: true, message: 'Please input your username!' }]"
        >
          <a-input v-model:value="formState.password" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script setup>
import { ref, reactive, defineProps } from "vue"
import { message } from "ant-design-vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
const store = useStore()

const visible = ref(false)
const formState = reactive({
	account: "",
	password: "",
})

const props = defineProps({
	parentInfo: Object,
})

const openAccountModal = () => {
	visible.value = true
}

const loginLazada = async () => {
	try {
		const result = await axios.post(api.getAccount, {
			shopId: props.parentInfo.id,
		})
		const account = {
			username: result.account,
			password: result.password,
		}
		console.log(account)
		window.open("https://sellercenter.lazada.co.th/apps/seller/login")
		try {
			// eslint-disable-next-line
			chrome.runtime.sendMessage(account, "idass", () => {})
		} catch (error) {
			message.error("请先安装浏览器插件")
		}
	} catch (error) {
		message.error("请先输入用户名密码")
	}
}

const handleOk = async () => {
	await axios.post(api.saveAccount, {
		shopAccount: {
			merchantId: store.getters["user/merchantId"],
			shopId: props.parentInfo.id,
			account: formState.account,
			password: formState.password,
		},
	})
	visible.value = false
}
</script>
<style lang="scss" scoped></style>
