<template>
	<div class="add">
		<a-card class="add-container">
			<a-form style="width: 500px; margin: 0 auto" ref="merchantRef" :model="ruleForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-item ref="name" label="商户名称" name="name">
					<a-input v-model:value="ruleForm.name" />
				</a-form-item>
				<a-form-item ref="userName" v-if="!id" label="主账户" name="userName">
					<a-input v-model:value="ruleForm.userName" />
				</a-form-item>
				<a-form-item ref="trade" label="行业" name="trade">
					<SelectTradeList v-model:value="ruleForm.trade" />
				</a-form-item>
				<a-form-item ref="mobile" label="手机号" name="mobile">
					<a-input v-model:value="ruleForm.mobile" />
				</a-form-item>
				<a-form-item ref="merchantType" label="商户类型" name="merchantType">
					<a-select v-model:value="ruleForm.merchantType">
						<a-select-option value="COMPANY"> 单个公司 </a-select-option>
						<a-select-option value="GROUP"> 集团公司 </a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item ref="appTypeList" label="应用提供商-应用类型" name="appTypeList">
					<a-select mode="multiple" v-model:value="ruleForm.appTypeList">
						<a-select-option v-for="(item, index) in appList" :key="index" :value="item.appType">
							{{ item.desc }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item ref="serviceTypeList" label="服务提供商-服务类型" name="serviceTypeList">
					<a-select mode="multiple" v-model:value="ruleForm.serviceTypeList">
						<a-select-option v-for="(item, index) in serviceList" :key="index" :value="item.serviceType">
							{{ item.desc }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item :wrapper-col="{ span: 14, offset: 8 }">
					<a-button type="primary" @click="submitAdd" :loading="loading">
						{{ id ? "修改" : "创建" }}
					</a-button>
					<a-button style="margin-left: 10px" @click="backUp">返回</a-button>
				</a-form-item>
			</a-form>
		</a-card>
	</div>
</template>

<script>
import { reactive, ref, toRefs, onActivated, onBeforeUnmount, getCurrentInstance } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"
import { useRoute, useRouter } from "vue-router"
import { localGet } from "@/common/utils"
import SelectTradeList from "@/components/SelectTradeList"
export default {
	name: "AddMerchant",
	components: { SelectTradeList },
	setup() {
		const { proxy } = getCurrentInstance()
		console.log("proxy", proxy)
		const editor = ref(null)
		const merchantRef = ref(null)
		const route = useRoute()
		const router = useRouter()

		const isNameHave = async (type, value) => {
			console.log(state.id)
			let data = {}
			let idParams = state.id ? state.id.id : ""
			if (type == "name") {
				data = { id: idParams || "", name: value, nameType: "MERCHANT" }
			} else {
				data = { id: idParams || "", name: value, nameType: "USER" }
			}
			let exist = false
			await axios.post(api.isNameHave, data).then((res) => {
				exist = res.exist
			})
			return exist
		}
		let validateName = async () => {
			if (state.ruleForm.name === "") {
				return Promise.reject("请输入商户名称")
			} else if (state.ruleForm.name !== "") {
				if (await isNameHave("name", state.ruleForm.name)) {
					return Promise.reject("当前名称重复，请重新输入")
				}
				return Promise.resolve()
			}
		}
		let validateUserName = async () => {
			if (state.ruleForm.userName === "") {
				return Promise.reject("请输入主账户名称")
			} else if (state.ruleForm.userName !== "") {
				if (await isNameHave("username", state.ruleForm.userName)) {
					return Promise.reject("当前名称重复，请重新输入")
				}
				return Promise.resolve()
			}
		}
		const state = reactive({
			token: localGet("token") || "",
			id: "",
			defaultCate: "",
			ruleForm: {
				id: "",
				mobile: "",
				name: "",
				userName: "",
				appTypeList: undefined,
				serviceTypeList: undefined,
				trade: undefined,
			},
			rules: {
				name: [{ required: "true", validator: validateName, trigger: "blur" }],
				mobile: [{ required: "true", message: "请填写手机号", trigger: "blur" }],
				userName: [{ required: "true", validator: validateUserName, trigger: "blur" }],
			},
			appList: [],
			serviceList: [],
			loading: false,
		})

		onActivated(() => {
			merchantRef.value.resetFields()
			state.id = route.query.id
			getAppList()
			getServiceList()
			state.ruleForm = {}
			if (state.id) {
				state.id = JSON.parse(state.id)
				state.ruleForm.id = state.id.id
				state.ruleForm.mobile = state.id.mobile
				state.ruleForm.name = state.id.name
				state.ruleForm.userName = state.id.createdUser
				state.ruleForm.appTypeList = state.id.appTypeList || undefined
				state.ruleForm.serviceTypeList = state.id.serviceTypeList || undefined
				state.ruleForm.trade = state.id.trade || undefined
			}
			console.log(state.ruleForm)
		})
		onBeforeUnmount(() => {})
		const submitAdd = () => {
			merchantRef.value
				.validate()
				.then(() => {
					state.loading = true
					const params = { merchant: { ...state.ruleForm } }
					axios
						.post(api.saveMerchant, params)
						.then(() => {
							message.success(state.id ? "修改成功" : "添加成功")
							state.loading = false
							router.push({ name: "merchantManage" })
						})
						.catch(() => {
							state.loading = false
						})
				})
				.catch((error) => {
					state.loading = false
					console.log("error", error)
				})
		}
		const backUp = () => {
			console.log("router", router)
			router.back()
		}
		const getAppList = async () => {
			let res = await axios.post(api.getAppTypeList, {})
			state.appList = res
		}
		const getServiceList = async () => {
			let res = await axios.post(api.getServiceTypeList, {})
			state.serviceList = res
		}
		return {
			...toRefs(state),
			merchantRef,
			submitAdd,
			editor,
			backUp,
			labelCol: { span: 8 },
			wrapperCol: { span: 16 },
		}
	},
}
</script>

<style scoped>
.add {
	display: flex;
}
.add-container {
	flex: 1;
	height: 100%;
}
.avatar-uploader {
	width: 100px;
	height: 100px;
	color: #ddd;
	font-size: 30px;
}
.avatar-uploader-icon {
	display: block;
	width: 100%;
	height: 100%;
	border: 1px solid #e9e9e9;
	padding: 32px 17px;
}
</style>
