import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import "./assets/css/common.scss"
import "./assets/iconfont/iconfont.css"
import "ant-design-vue/dist/antd.css"
import * as Icons from "@ant-design/icons-vue"
import { antdComponents } from "./antd"
import { registerMicroApps } from "qiankun"
import apps from "./qiankun/apps.js"
registerMicroApps(apps)
localStorage.setItem("appCode", "IDAAS")
const app = createApp(App)
antdComponents.forEach((item) => {
	app.use(item)
})
// 全局使用图标

import qs from "qs"
import md5 from "js-md5"
const icons = Icons
for (const i in icons) {
	app.component(i, icons[i])
}

app.config.globalProperties.$qs = qs
app.config.globalProperties.$md5 = md5

app.use(store).use(router).use(ElementPlus).mount("#app")
