const state = {
	userInfo: JSON.parse(localStorage.getItem("userInfo")) || null,
	website: JSON.parse(localStorage.getItem("website")) || "",
}
const getters = {
	user: (state) => state.userInfo?.user,
	appId: (state) => state.userInfo?.user?.appId,
	userName: (state) => state.userInfo?.user?.userName,
	merchantName: (state) => state.userInfo?.user?.merchantName,
	userId: (state) => state.userInfo?.user?.id,
	merchantId: (state) => state.userInfo?.user?.merchantId,
	merchantCode: (state) => state.userInfo?.user?.merchantCode,
	merchantType: (state) => state.userInfo?.user?.merchant?.merchantType,
	country: (state) => state.userInfo?.user?.merchant?.country,
	isAdmin: (state) => state.userInfo?.user?.isAdmin,
	isGeez: (state) => state.userInfo?.user?.merchantName === "GEEZ",
}
const mutations = {
	SET_USERINFO(state, val) {
		localStorage.setItem("userInfo", JSON.stringify(val))
		state.userInfo = val
	},
	SET_WEBSITE(state, val) {
		localStorage.setItem("website", JSON.stringify(val))
		state.userInfo = val
	},
}

const actions = {
	LOGIN(context, state) {
		context.commit("SET_USERINFO", state)
	},
	SET_WEBSITE_VALUE(context, state) {
		context.commit("SET_WEBSITE", state)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
