<template>
	<div class="add">
		<a-card class="add-container">
			<a-form style="width: 500px; margin: 0 auto" ref="merchantRef" :model="ruleForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-item ref="userName" label="用户名称" name="userName">
					<a-input v-model:value="ruleForm.userName" :disabled="id ? true : false" />
				</a-form-item>
				<a-form-item ref="mobile" label="手机号" name="mobile">
					<a-input v-model:value="ruleForm.mobile" />
				</a-form-item>
				<a-form-item v-if="id ? true : false" label="密码">
					<a-input v-model:value="ruleForm.password" />
				</a-form-item>
				<a-form-item v-else ref="password" label="密码" name="password">
					<a-input v-model:value="ruleForm.password" />
				</a-form-item>
				<a-form-item ref="department" label="部门" name="department">
					<a-cascader v-model:value="ruleForm.department" :options="options" placeholder="Please select" @change="getDeparment" />
				</a-form-item>
				<a-form-item label="是否限制IP" name="ipLimit">
					<a-radio-group v-model:value="ruleForm.ipLimit">
						<a-radio :value="0">不限制IP</a-radio>
						<a-radio :value="1">限制IP</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item v-if="ruleForm.ipLimit === 1" label="允许IP" name="accessIpList">
					<a-textarea placeholder="请输入IP地址，用英文逗号隔开" v-model:value="ruleForm.accessIpList" />
				</a-form-item>
				<a-form-item label="真实姓名" name="realName">
					<a-input v-model:value="ruleForm.realName" />
				</a-form-item>
				<a-form-item label="邮箱" name="email">
					<a-input v-model:value="ruleForm.email" />
				</a-form-item>

				<a-form-item :wrapper-col="{ span: 14, offset: 4 }">
					<a-button type="primary" @click="submitAdd">
						{{ id ? "修改" : "创建" }}
					</a-button>
					<a-button style="margin-left: 10px" @click="backUp">返回</a-button>
				</a-form-item>
			</a-form>
		</a-card>
	</div>
</template>

<script>
import { reactive, ref, toRefs, onActivated, onBeforeUnmount, getCurrentInstance } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"
import { useRoute, useRouter } from "vue-router"
import { localGet } from "@/common/utils"
import { useStore } from "vuex"
import sha512 from "js-sha512"
import { debounce } from "lodash"
export default {
	name: "addUser",
	setup() {
		const { proxy } = getCurrentInstance()
		console.log("proxy", proxy)
		const editor = ref(null)
		const merchantRef = ref(null)
		const route = useRoute()
		const router = useRouter()
		const store = useStore()
		const isNameHave = async (type, value) => {
			console.log(state.id)
			let data = {}
			const idParams = state.id ? state.id.id : ""
			data = { id: idParams || "", name: value, nameType: "USER" }
			let exist = false
			await axios
				.post(api.isNameHave, data)
				.then((res) => {
					exist = res.exist
				})
				.catch((err) => {
					console.log(err)
				})
			return exist
		}
		const validateName = async () => {
			if (state.ruleForm.userName === "") {
				return Promise.reject("请输入用户名称")
			}
			if (state.ruleForm.userName !== "") {
				if (await isNameHave("name", state.ruleForm.userName)) {
					return Promise.reject("当前名称重复，请重新输入")
				}
				return Promise.resolve()
			}
		}
		const validateIp = async () => {
			if (state.ruleForm.accessIpList === "") {
				return Promise.reject("请输入Ip地址")
			}
			if (state.ruleForm.accessIpList !== "") {
				let isTrue = false
				state.ruleForm.accessIpList.split(",").forEach((item) => {
					if (!checkIp(item)) {
						isTrue = true
					}
				})
				if (isTrue) {
					return Promise.reject("当前IP地址不符合规范，请重新输入")
				}
				return Promise.resolve()
			}
		}
		const state = reactive({
			token: localGet("token") || "",
			id: "",
			defaultCate: "",
			ruleForm: {
				merchantId: store.getters["user/merchantId"],
				mobile: "",
				password: "",
				userName: "",
				realName: "",
				email: "",
				qq: "",
				wechat: "",
				ipLimit: 0,
				accessIpList: "",
				isMaster: 0,
			},
			rules: {
				userName: [{ required: "true", validator: validateName, trigger: "blur" }],
				mobile: [{ required: "true", message: "请填写手机号", trigger: "blur" }],
				password: [{ required: "true", message: "请填写密码", trigger: "blur" }],
				realName: [{ required: "true", message: "请填写真实姓名", trigger: "blur" }],
				accessIpList: [{ required: "true", validator: validateIp, trigger: "blur" }],
			},
			departmentParamas: {
				level: "",
				merchantId: store.getters["user/merchantId"],
				type: 1,
				parentId: 0,
				status: "",
				tree: true,
			},
			options: [],
			page: "",
		})

		onActivated(() => {
			state.ruleForm = {
				merchantId: store.getters["user/merchantId"],
				mobile: "",
				password: "",
				userName: "",
				realName: "",
				email: "",
				qq: "",
				wechat: "",
				ipLimit: 0,
				accessIpList: "",
				isMaster: 0,
			}
			if (route.query.id) {
				state.id = JSON.parse(route.query.id)
			} else {
				state.id = ""
			}
			if (state.id) {
				state.id.password = ""
				state.ruleForm = state.id
				state.ruleForm.department = state.ruleForm.departmentList.map((item) => {
					return item.departmentId
				})
				console.log(state.ruleForm)
				// state.ruleForm.password = "";
			}
			console.log("page", route.query.page)
			state.page = route.query.page || ""
			getDeparmentList()
		})
		onBeforeUnmount(() => {})
		const checkIp = (ip) => {
			const rep =
				/^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
			console.log(rep.test(ip))
			if (!rep.test(ip)) {
				return false
			}
			return true
		}

		const submitAdd = debounce(() => {
			const user = { ...state.ruleForm }
			console.log(user)
			const userDepartmentList = []
			if (user.department) {
				user.department.forEach((item) => {
					userDepartmentList.push({
						departmentId: item,
						status: true,
					})
				})
				user.userDepartmentList = userDepartmentList
			} else {
				user.userDepartmentList = []
			}

			user.password = user.password ? sha512(user.password) : ""
			if (user.ipLimit === 1 && user.accessIpList.indexOf(",") > -1) {
				user.accessIpList = user.accessIpList.split(",")
			} else if (user.ipLimit === 1) {
				user.accessIpList = [user.accessIpList]
			}
			merchantRef.value
				.validate()
				.then(() => {
					const params = { user }
					axios
						.post(api.saveUser, params)
						.then((res) => {
							if (state.id) {
								message.success("修改成功")
								router.push({
									name: "accountManage",
									query: {
										page: state.page,
									},
								})
							} else {
								message.success("添加成功，请给用户添加业务组和权限")
								router.push({
									name: "authAccount",
									query: {
										userId: res.id,
										userName: user.userName,
										page: state.page,
									},
								})
							}
						})
						.catch((err) => {
							console.log(err)
						})
				})
				.catch((error) => {
					console.log("error", error)
				})
		})
		const backUp = () => {
			console.log("router", router)
			router.push({
				name: "accountManage",
				query: {
					page: state.page,
				},
			})
		}
		const getDeparmentList = () => {
			axios.post(api.departmentList, { ...state.departmentParamas }).then((res) => {
				addKey(res)
				console.log(res)
				state.options = res
			})
		}
		const addKey = (value) => {
			value.forEach((item) => {
				item.value = String(item.id)
				item.label = item.name
				if (item.children) {
					addKey(item.children)
				}
			})
		}
		const getDeparment = (value) => {
			console.log(value)
		}
		return {
			...toRefs(state),
			merchantRef,
			submitAdd,
			editor,
			backUp,
			getDeparmentList,
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			addKey,
			getDeparment,
		}
	},
}
</script>

<style scoped>
.add {
	display: flex;
}
.add-container {
	flex: 1;
	height: 100%;
}
.avatar-uploader {
	width: 100px;
	height: 100px;
	color: #ddd;
	font-size: 30px;
}
.avatar-uploader-icon {
	display: block;
	width: 100%;
	height: 100%;
	border: 1px solid #e9e9e9;
	padding: 32px 17px;
}
/* :deep(.ant-form-item-with-help) {
  margin-bottom: 24px;
} */
</style>
