<template>
  <div class="a-content-login">
    <div class="top">
      <p class="title">IDaaS</p>
      <p class="titleMini">
        End To End 智能应用中心
        <span class="tmRight">tm</span>
      </p>
    </div>
    <div class="content">
      <a-card
        hoverable
        v-for="item in appList"
        :key="item.merchantId"
        class="cardStyle"
      >
        <div class="toAppCard" @click="toApp(item)">
          <p class="cardIcon">
            <a-avatar :size="64" :src="item.icon"> </a-avatar>
          </p>
          <p class="introduce">{{ item.title }}</p>
        </div>
        <a-row>
          <a-col
            :span="12"
            v-for="(otem, index) in item.appApiList"
            :key="item.merchantId + index"
            class="toAppTitle"
            @click="toApp(item, otem.apiPath)"
          >
            <span class="toAppTitleMini">{{ otem.apiName }}</span>
            <!-- <right-circle-two-tone /> -->
            <!-- <right-circle-outlined /> -->
            <right-circle-filled class="toAppTitleIcon" />
          </a-col>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
export default {
	name: "loginApp",
	setup() {
		const store = useStore()
		const state = reactive({
			appList: [],
		})
		onMounted(() => {
			getList()
		})
		const getList = () => {
			const data = {
				merchantId: store.getters["user/merchantId"],
			}
			axios.post(api.dashboardList, data).then((res) => {
				state.appList = res
			})
		}
		const toApp = (value, path) => {
			console.log(value, path)

			if (value) {
				const data = {
					merchantAppId: value.merchantAppId,
				}
				axios.post(api.getAccessInfo, data).then((res) => {
					const url = `${res.url}`
					if (res.accessToken && res.url) {
						window.open(url)
					}
				})
			} else {
				console.log(value)
			}
		}
		return {
			...toRefs(state),
			getList,
			toApp,
		}
	},
}
</script>
<style lang="scss" scoped>
.a-content-login {
  .top {
    width: 100%;
    text-align: center;
    margin-top: 55px;
    p {
      font-weight: 500;
      color: #293350;
    }
    .title {
      line-height: 30px;
      font-size: 35px;
      font-weight: 700;
      margin: 0;
    }
    .titleMini {
      line-height: 46px;
      font-size: 15px;
      font-weight: 700;
      .tmRight {
        color: rgb(155 151 151 / 85%);
        display: inline-block;
        position: relative;
        top: -7px;
        font-size: 12px;
        left: -3px;
      }
    }
  }
  .content {
    // width: 1000px;
    margin: 0 auto;
    display: flex;
    // padding-top: 30px;
    justify-content: space-around;
    flex-wrap: wrap;
    .cardStyle {
      // box-shadow: 0 0 20px 0 #f2f2f7;
      width: 300px;
      margin: 20px 0;
      .cardIcon {
        display: flex;
        justify-content: space-around;
      }
      .introduce {
        text-align: center;
        font-weight: 900;
      }
      .toAppTitle {
        text-align: center;
        line-height: 25px;
        color: #86868b;
      }
      .toAppTitle:hover {
        color: #1890ff;
        font-weight: 900;
        font-size: 15px;
      }
      .toAppTitleMini {
        margin-right: 5px;
      }
      .toAppTitleIcon {
        color: #43a0f7;
      }
    }
  }
}
</style>
