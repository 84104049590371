<template>
  <a @click="addApplication">{{ text }}</a>
  <a-modal v-model:visible="state.shopifyNameModal" width="500px" title="店铺名称">
    <template #footer>
      <a-button type="primary" @click="goToAuthHandle">去授权</a-button>
    </template>
    <a-form name="basic">
      <a-form-item label="店铺名称">
        <a-input
          v-model:value="state.shopName"
          :focus="true"
          placeholder="请输入店铺名(英文)"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { reactive, defineProps, computed } from "vue"
import { localSet, localRemove } from "@/common/utils"
import { useRouter } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"

const router = useRouter()

const props = defineProps({
	reOauth: Boolean,
	record: {
		type: Object,
		default: () => ({}),
	},
})

const text = computed(() => {
	return props.reOauth ? "重新授权" : "添加应用"
})

const state = reactive({
	modalInfo: {},
	shopifyNameModal: false,
	shopName: "",
})

const addApplication = () => {
	const applicationValue = `${props.reOauth ? "reAuth" : "add"}IDAAS${props.record.id}`
	let redirectUrl = `https://${window.location.hostname}/oauth?value=${applicationValue}`
	localSet("applicationValue", applicationValue)
	let appCode = props.record.appCode || props.record.code
	switch (appCode) {
		case "LAZADA":
			window.open(
				`https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${encodeURIComponent(
					redirectUrl
				)}&client_id=120699`
			)
			break
		case "SHOPEE":
		case "TIKTOK":
		case "TMALL":
		case "TAOBAO":
		case "TAOBAO_WAYBILL_TMS": //淘宝快递面单应用授权
		case "KWAIXIAODIAN":
			{
				let data = {
					appCode,
					redirectUrl,
				}
				goToAuth(data)
			}
			break
		case "SHOPIFY":
			state.modalInfo = {
				appCode,
				redirectUrl,
			}
			state.shopName = ""
			state.shopifyNameModal = true
			break
		default:
			router.push({
				name: "saveApplication",
				query: {
					type: "add",
					value: JSON.stringify(props.record),
				},
			})
	}
}

const goToAuth = (data) => {
	localRemove("reAuth")
	if (props.reOauth) {
		localSet("reAuth", 1)
	}
	axios.post(api.appGetAuthUrl, data).then((res) => {
		window.open(res)
	})
}

const goToAuthHandle = () => {
	if (!state.shopName) return message.warning("请输入店铺名")
	goToAuth({
		...state.modalInfo,
		name: state.shopName,
	})
}
</script>