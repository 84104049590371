<template>
  <a-card class="account-container">
    <a-form
      style="width: 400px"
      ref="ruleFormRef"
      name="custom-validation"
      :model="ruleForm"
      :rules="rules"
      @finish="submitPass"
      :wrapper-col="wrapperCol"
      :label-col="labelCol"
    >
      <a-form-item
        has-feedback
        label="旧密码"
        name="oldpass"
        :labelCol="{ span: 8, offset: 0 }"
      >
        <a-input-password v-model:value="ruleForm.oldpass" autocomplete="off" />
      </a-form-item>
      <a-form-item
        has-feedback
        label="新密码"
        name="newpass"
        :labelCol="{ span: 8, offset: 0 }"
      >
        <a-input-password v-model:value="ruleForm.newpass" autocomplete="off" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 2 }">
        <a-button type="primary" style="margin-left: 100px" html-type="submit">
          确定
        </a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { ElMessage } from "element-plus"
import sha512 from "js-sha512"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
export default {
	name: "account",

	setup() {
		const nameRef = ref(null)
		const passRef = ref(null)
		const ruleFormRef = ref()
		const router = useRouter()
		const store = useStore()
		let oldpass = async (_rule, value) => {
			if (value === "") {
				return Promise.reject("请输入原始密码")
			} else {
				if (state.ruleForm.newpass !== "") {
					ruleFormRef.value.validateFields("newpass")
				}

				return Promise.resolve()
			}
		}
		let validatePass = async (_rule, value) => {
			let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@_#.$%^&*])[A-Za-z\d~!@_#.$%^&*]{8,20}$/
			if (value === "") {
				return Promise.reject("密码不能为空，请重新输入")
			} else if (!reg.test(value)) {
				return Promise.reject("密码长度应为8-20位，并且必须包含大写和小写字母、数字、特殊符号")
			} else {
				return Promise.resolve()
			}
		}
		const state = reactive({
			user: null,
			ruleForm: {
				oldpass: "",
				newpass: "",
			},
			rules: {
				oldpass: [
					{
						validator: oldpass,
						trigger: "change",
					},
				],
				// "^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,}$";
				newpass: [
					{
						validator: validatePass,
						trigger: "change",
					},
				],
			},
		})

		onMounted(() => {})
		const submitPass = () => {
			console.log(1)
			let data = {
				oldPassword: state.ruleForm.oldpass,
				newPassword: state.ruleForm.newpass,
			}
			data.oldPassword = sha512(data.oldPassword)
			data.newPassword = sha512(data.newPassword)

			axios
				.post(api.changePassword, {
					merchantId: store.getters["user/merchantId"],
					oldPassword: data.oldPassword,
					newPassword: data.newPassword,
				})
				.then(() => {
					ElMessage.success("Modified successfully")
					// window.location.reload();
					// localStorage.removeItem("userInfo");
					router.push("/overview")
				})
		}
		return {
			...toRefs(state),
			nameRef,
			labelCol: { style: { width: "80px" } },
			wrapperCol: { span: 20 },
			passRef,
			ruleFormRef,
			submitPass,
		}
	},
}
</script>

<style>
.account-container {
  margin-bottom: 20px;
}
</style>
