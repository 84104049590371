<template>
	<a-card class="auth-system-container">
		<template #title>
			<a-alert v-if="dialogState.authAlert === 'open'" message="权限系统" type="info" show-icon closable class="auth-alert" @close="closeAlert">
				<template #icon>
					<bulb-outlined class="alert-icon" />
				</template>
				<template #description>
					<div class="alert-content">
						权限系统是基于RBAC（Role-Based Access Control，基于角色的访问控制）的权限模型。
						<br />
						既可以管理 IDaaS 自身资源权限，也可以管理第三方系统的二级菜单功能按钮等资源权限；当授予某个 IDaaS
						用户开发者权限，在未来将可以创建出新的自定义权限系统，以支持系统的三级授权。
						<br />
						默认系统只能关联开发者角色，其它功能不可用；自建系统可以正常使用，用于集中管理第三方系统的权限。
					</div>
				</template>
			</a-alert>

			<div class="header-actions">
				<a-button v-if="state.isDeveloper" type="primary" @click="handleAdd">新增系统</a-button>
			</div>
		</template>

		<a-table :loading="state.loading" :columns="columns" :data-source="state.tableData" :scroll="{ y: tableHeight }" row-key="id" :pagination="false">
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'merchantAppVOList'">
					{{ record.merchantAppVOList?.[0]?.name || "" }}
				</template>

				<template v-if="column.key === 'status'">
					<a-switch v-model:checked="record.status" />
				</template>

				<template v-if="column.key === 'action'">
					<div class="action-buttons">
						<a @click="managePage(record, 'oauthDetail')">授权管理</a>
						<a @click="managePage(record, 'roleManage')">角色管理</a>
						<a v-if="state.isDeveloper" @click="managePage(record, 'resource')">资源管理</a>
						<a v-if="state.isDeveloper" @click="handleEdit(record)">修改</a>
						<div class="action-buttons-secondary">
							<a v-if="state.isDeveloper" @click="copyHandle(record)">复制菜单和功能</a>
						</div>
					</div>
				</template>
			</template>
		</a-table>

		<a-drawer
			:title="formState.edit ? '编辑系统' : '新增系统'"
			placement="right"
			width="640"
			:visible="dialogState.dialogVisible"
			@close="dialogState.dialogVisible = false"
			:closable="false"
		>
			<a-form :model="formState.authoritySystem" layout="horizontal" @finish="onFinish" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="名称" name="name" :rules="[{ required: true, message: '请输入名称' }]">
					<a-input v-model:value="formState.authoritySystem.name" />
				</a-form-item>

				<a-form-item label="关联应用" name="code" :rules="[{ required: true, message: '请选择关联应用' }]">
					<CommonQuerySelect
						:init="true"
						api="getAppList"
						:params="{ appTypeList: ['PRODUCT'] }"
						placeholder="请选择关联应用"
						v-model:code="formState.authoritySystem.code"
					/>
				</a-form-item>

				<a-form-item label="描述" name="desc" :rules="[{ required: true, message: '请输入系统描述' }]">
					<a-input v-model:value="formState.authoritySystem.desc" />
				</a-form-item>

				<a-form-item :wrapper-col="{ offset: 8, span: 16 }">
					<a-button type="primary" html-type="submit">提交</a-button>
				</a-form-item>
			</a-form>
		</a-drawer>
	</a-card>
</template>

<script setup>
import { computed, reactive, onMounted } from "vue"
import { useRouter } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
import { localGet, localSet } from "@/common/utils"
import { message } from "ant-design-vue"
import CommonQuerySelect from "@/components/CommonQuerySelect"
import { cloneDeep } from "lodash"

// Table columns configuration
const columns = [
	{ title: "系统名称", dataIndex: "name", key: "name" },
	{ title: "系统ID", dataIndex: "code", key: "code" },
	{ title: "绑定应用名称", dataIndex: "merchantAppVOList", key: "merchantAppVOList" },
	{ title: "系统状态", dataIndex: "status", key: "status" },
	{ title: "系统描述", dataIndex: "desc", key: "desc" },
	{ title: "操作", key: "action", width: 320 },
]

const router = useRouter()
const store = useStore()

// State management
const state = reactive({
	isDeveloper: false,
	loading: false,
	tableData: [],
	merchantAppId: "",
})

const params = reactive({
	name: "",
	total: 0,
	page_no: 1,
	page_size: 10,
})

const formState = reactive({
	authoritySystem: {},
	merchantAppIdNew: "",
	edit: false,
	appList: [],
})

const dialogState = reactive({
	dialogVisible: false,
	authAlert: localGet("authAlert") || "open",
	visibleSecret: false,
	secretConfirm: false,
	selectApp: false,
})

// Computed properties
const tableHeight = computed(() => window.innerHeight - (dialogState.authAlert === "open" ? 460 : 300))

// Methods
const getDataList = async () => {
	try {
		state.loading = true
		const data = {
			...params,
			merchantId: store.getters["user/merchantId"],
		}
		data.total = undefined

		const response = await axios.post(api.authoritySystemList, data)
		state.tableData = response.map((item) => ({
			...item,
			status: item.status === 1,
		}))
		params.total = response.length
	} catch (error) {
		console.error("Failed to fetch data:", error)
		message.error("获取数据失败")
	} finally {
		state.loading = false
	}
}

const handleAdd = () => {
	dialogState.dialogVisible = true
	formState.edit = false
	formState.authoritySystem = {}
	formState.merchantAppIdNew = ""
}

const handleEdit = (record) => {
	formState.edit = true
	formState.authoritySystem = cloneDeep(record)
	formState.merchantAppIdNew = record.merchantAppVOList?.[0]
		? `${record.merchantAppVOList[0].id}-${record.merchantAppVOList[0].name}`
		: ""
	dialogState.dialogVisible = true
}

const onFinish = async () => {
	try {
		formState.authoritySystem.merchantId = store.getters["user/merchantId"]
		await axios.post(api.authoritySystemSave, {
			authoritySystem: formState.authoritySystem,
			merchantAppId: formState.merchantAppIdNew.includes("-") ? formState.merchantAppIdNew.split("-")[0] : "",
		})

		message.success(`${formState.edit ? "修改成功" : "添加成功"}`)
		getDataList()
		dialogState.dialogVisible = false
	} catch (error) {
		console.error("Save failed:", error)
		message.error("保存失败")
	}
}

const managePage = (record, type) => {
	const query = {
		...record,
		appList: record.appList ? JSON.stringify(record.appList) : undefined,
		merchantAppVOList: record.merchantAppVOList ? JSON.stringify(record.merchantAppVOList) : "",
	}
	router.push({ name: type, query })
}

const copyHandle = async (record) => {
	try {
		const response = await axios.post(api.resourceList, {
			all: true,
			tree: true,
			merchantId: store.getters["user/merchantId"],
			authSysCode: record.code,
			resourceTypes: ["MENU", "FUNC"],
		})
		const menuList = []
		const funcList = []
		response.forEach((item) => {
			if (item.type === "MENU") {
				menuList.push(item)
			} else if (item.type === "FUNC") {
				funcList.push(item)
			}
		})
		message.success("复制成功")
		localSet("copyMenuList", menuList)
		localSet("copyFuncList", funcList)
	} catch (error) {
		console.error("Copy failed:", error)
		message.error("复制失败")
	}
}

const closeAlert = () => {
	dialogState.authAlert = "close"
	localSet("authAlert", "close")
}

// Initialize
onMounted(() => {
	state.isDeveloper = store.getters["user/merchantName"] === "GEEZ"
	getDataList()
})
</script>

<style lang="scss" scoped>
.auth-system-container {
	min-height: 100%;
	padding: 20px;

	.auth-alert {
		margin-bottom: 20px;

		.alert-icon {
			margin-top: 20px;
		}

		.alert-content {
			font-size: 14px;
			line-height: 1.6;
		}
	}

	.header-actions {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}

	.action-buttons {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;

		a {
			color: #1890ff;

			&:hover {
				color: #40a9ff;
			}
		}

		&-secondary {
			display: flex;
			gap: 12px;
		}
	}
}

:deep(.ant-table) {
	.ant-table-thead > tr > th {
		background: #fafafa;
		font-weight: 500;
	}

	.ant-table-tbody > tr > td {
		padding: 12px 16px;
	}
}

:deep(.ant-drawer) {
	.ant-drawer-header {
		padding: 16px 24px;
		border-bottom: 1px solid #f0f0f0;
	}

	.ant-drawer-body {
		padding: 24px;
	}
}
</style>
