<template>
	<div></div>
</template>

<script setup>
import { onMounted } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { deleteCookie } from "@/common/utils/cookie"
import { useRoute } from "vue-router"
import { message } from "ant-design-vue"

const route = useRoute()

const logout = async () => {
	try {
		await axios.post(api.loginOut)
		logoutHandle()
	} catch (error) {
		if (error.subCode === "sys.user-error-unlogin") {
			logoutHandle()
		}
	}
}

const logoutHandle = () => {
	message.destroy()
	localStorage.clear()
	deleteCookie(() => {
		window.location.href = decodeURIComponent(route.query.redirect_uri)
	})
}

onMounted(() => {
	logout()
})
</script>

<style></style>
