<template>
  <div class="tms-account-oauth">
    <h2 class="title">菜鸟快递账号授权</h2>
    <a-form
      ref="merchantRef"
      :model="formInfo"
      @finish="saveInfo"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item
        name="name"
        :rules="[
          {
            required: true,
            message: '请输入账号名称',
            trigger: 'blur',
          },
        ]"
        label="账号名称"
      >
        <a-input v-model:value="formInfo.name" placeholder="请输入账号名称" />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: '请输入账号code',
            trigger: 'blur',
          },
        ]"
        name="code"
        label="账号code"
      >
        <a-input v-model:value="formInfo.code" placeholder="请输入账号code" />
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 6, span: 16 }">
        <a-button type="primary" html-type="submit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue"
import { localGet } from "@/common/utils"
import { useRouter } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"
export default {
	name: "tmsAccountOauth",
	setup() {
		const router = useRouter()
		const state = reactive({
			code: "",
			formInfo: {
				name: "",
				code: "",
			},
			labelCol: { span: 6 },
			wrapperCol: { span: 16 },
		})
		onMounted(() => {
			const searchParams = new URLSearchParams(window.location.search)
			const code = searchParams.get("accessCode")
			state.code = code
		})

		const saveInfo = () => {
			let id = localGet("cainiaoTmsId"),
				merchantAppId = localGet("cainiaoTmsMerchantAppId")
			const data = {
				name: state.formInfo.name,
				code: state.formInfo.code,
				accessCode: state.code,
				merchantAppId,
			}
			if (id) {
				data.id = id
			}
			axios.post(api.tmsAccountAuth, data).then(() => {
				message.success("授权成功", 1.2).then(() => {
					router.push({
						name: "applicationList",
					})
				})
			})
		}

		const refData = toRefs(state)
		return {
			...refData,
			saveInfo,
		}
	},
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
</style>
