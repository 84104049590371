<template>
  <div>
    <a-card title="账号信息" class="cardMiniBox" size="small">
      <p class="cardContent">管理应用内账号信息</p>
      <p class="cardP">
        <a type="text" style="color: #1890ff" @click="openList()">账号管理</a>
      </p>
    </a-card>
    <a-drawer
      width="900"
      placement="right"
      v-model:visible="state.visible"
      @close="close()"
      :closable="false"
      title="账号管理"
    >
      <a-row style="margin-bottom: 10px" :gutter="16">
        <!-- <a-col v-if="props.parentInfo.appCategory == 'WMS'"
          ><a-button
            @click="warehouseSync(props.parentInfo)"
            style="margin-left: 10px"
            type="primary"
            >同步数据</a-button
          ></a-col
        > -->
        <a-col>
          <a-button @click="addList" style="margin-left: 10px" type="primary">
            新增账号
          </a-button>
        </a-col>
      </a-row>
      <a-table
        :columns="state.warehouseList"
        rowKey="id"
        :scroll="{ y: 800 }"
        :data-source="state.tableList"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: state.joinSelectKeys,
          onChange: onSelectJoin,
        }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'status'">
            <el-switch @click="saveInfo(record)" v-model="record.status" />
          </template>
          <template v-if="column.key === 'action'">
            <a @click="editList(record)">编辑</a>
            <a
              style="margin-left: 6px"
              @click="reOauth(record)"
              v-if="props.parentInfo.appCode === 'CAINIAO_TMS'"
            >
              重新授权
            </a>
          </template>
        </template>
      </a-table>
      <!-- 编辑账号 -->
      <a-drawer
        v-model:visible="state.editVisible"
        title="账号编辑"
        width="850"
        :closable="false"
      >
        <a-form
          style="margin: 0 auto"
          ref="merchantRef"
          layout="inline"
          :model="state.formInfo"
          @finish="saveInfo(null)"
          :label-col="state.labelCol"
          :wrapper-col="state.wrapperCol"
        >
          <a-form-item
            :rules="[
              {
                required: true,
                message: `请输入快递code`,
                trigger: 'blur',
              },
            ]"
            name="shippingCode"
            :label="`快递code`"
          >
            <a-auto-complete
              v-model:value="state.formInfo.shippingCode"
              :options="options"
              style="width: 200px"
              :disabled="state.editDisabled"
              placeholder="请输入快递code"
              @select="onSelect"
              @search="onSearch"
              @blur="onBlur"
            />
            <a-button type="primary" style="margin-left: 5px" @click="loadInfo">
              加载
            </a-button>
          </a-form-item>
          <a-form-item
            name="name"
            :rules="[
              {
                required: true,
                message: `请输入${state.infoTitle}名称`,
                trigger: 'blur',
              },
            ]"
            :label="`${state.infoTitle}名称`"
          >
            <a-input
              v-model:value="state.formInfo.name"
              :placeholder="`请输入${state.infoTitle}名称`"
            />
          </a-form-item>

          <a-form-item
            :rules="[
              {
                required: true,
                message: `请输入${state.infoTitle}code`,
                trigger: 'blur',
              },
            ]"
            name="code"
            :label="`${state.infoTitle}code`"
          >
            <a-input
              v-model:value="state.formInfo.code"
              :placeholder="`请输入${state.infoTitle}code`"
            />
          </a-form-item>
          <a-form-item
            :rules="[
              {
                required: true,
                message: `请输入${state.infoTitle}code`,
                trigger: 'blur',
              },
            ]"
            name="accountKey"
            :label="`accountKey`"
          >
            <a-input
              v-model:value="state.formInfo.accountKey"
              :placeholder="`请输入accountKey`"
            />
          </a-form-item>
          <a-form-item
            :rules="[
              {
                required: true,
                message: `请输入accountSecret`,
                trigger: 'blur',
              },
            ]"
            name="accountSecret"
            :label="`accountSecret`"
          >
            <a-input
              v-model:value="state.formInfo.accountSecret"
              :placeholder="`请输入accountSecret`"
            />
          </a-form-item>
          <a-form-item>
            <a-checkbox v-model:checked="state.formInfo.isIntegration">
              是否对接系统
            </a-checkbox>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
            <a-button type="primary" html-type="submit">保存</a-button>
          </a-form-item>
        </a-form>
      </a-drawer>
    </a-drawer>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue"

import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
import { message } from "ant-design-vue"
import { localSet } from "@/common/utils"
const store = useStore()
const state = ref({
	visible: false,
	editVisible: false,
	addVisible: false,
	warehouseList: [
		{
			title: "账号名称",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "账号code",
			dataIndex: "code",
			key: "code",
		},

		{
			title: "状态",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "操作",
			dataIndex: "action",
			key: "action",
		},
	],
	tableList: [],
	joinSelectKeys: [],
	infoTitle: "账号",
	formInfo: {}, //新增编辑
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
	id: "",
	authUrl: "",
})
const props = defineProps({
	parentInfo: Object,
})
const openList = (value) => {
	console.log(value)
	state.value.visible = true
	let data = {
		merchantId: store.getters["user/merchantId"],
		merchantAppId: props.parentInfo.id,
	}
	axios.post(api.tmsAccountList, data).then((res) => {
		state.value.tableList = res
	})
	if (props.parentInfo.appCode === "CAINIAO_TMS") {
		//菜鸟需要跳转授权
		axios
			.post(api.getMerchantAppDetail, {
				merchantAppId: props.parentInfo.id,
			})
			.then((res) => {
				let app = res.merchantAppResVO?.app?.appUrlList?.find((item) => item.countryCode === "DEFAULT")
				if (!app) return
				state.value.authUrl = `${app.authUrl}?isvAppKey=${res.merchantAppResVO.appKey}&redirectUrl=${app.frontCallbackUrl}`
			})
	}
}
const editList = (value) => {
	state.value.editVisible = true
	getTmsList("edit")
	state.value.formInfo = { ...value }
	// state.value.formInfo.code=value.tmsShippingId
	state.value.id = value.id
	state.value.tmsShippingId = value.tmsShippingId
}
const reOauth = (value) => {
	localSet("cainiaoTmsMerchantAppId", value.merchantAppId)
	localSet("cainiaoTmsId", value.id)
	window.location.href = state.value.authUrl
}
const addList = (value) => {
	if (props.parentInfo.appCode === "CAINIAO_TMS") {
		reOauth({
			merchantAppId: props.parentInfo.id,
			id: "",
		})
		return
	}
	state.value.editVisible = true
	state.value.tmsShippingId = ""
	state.value.formInfo = {
		isIntegration: 1,
	}
	getTmsList(value)
}
const onSelectJoin = (value) => {
	console.log(value)
	state.value.joinSelectKeys = value
}
const saveInfo = (record) => {
	// 有record 代表修改状态
	let id
	if (record) {
		state.value.formInfo = { ...record }
		id = record.id
	} else {
		state.value.formInfo.status = true
		id = state.value.id
	}
	state.value.formInfo.tmsShippingId = state.value.tmsShippingId
	state.value.formInfo.merchantId = store.getters["user/merchantId"]
	delete state.value.formInfo.shippingCode
	state.value.formInfo.merchantAppId = props.parentInfo.id

	// return
	let data = {
		tmsAccount: {
			...state.value.formInfo,
			id,
		},
	}
	axios.post(api.tmsAccountSave, data).then(() => {
		message.success("操作成功")
		state.value.editVisible = false
		openList()
	})
}
const options = ref([])
const getTmsList = async (code) => {
	let data = {
		merchantId: store.getters["user/merchantId"],
		// saasAppType: value.appType,
		code: code == "edit" ? "" : code,
		merchantAppId: props.parentInfo.id,
	}
	await axios.post(api.tmsShippingList, data).then((res) => {
		console.log(res)
		res.forEach((item) => {
			item.value = item.code
		})
		options.value = res
		options.value.forEach((item) => {
			if (item.id == state.value.formInfo.tmsShippingId) {
				state.value.tmsShippingId = state.value.formInfo.tmsShippingId
				state.value.formInfo.shippingCode = item.code
			}
		})
	})
}
const loadInfo = () => {
	state.value.isHas = false
	options.value.forEach((item) => {
		if (item.code == state.value.formInfo.shippingCode) {
			state.value.isHas = true
			state.value.tmsShippingId = item.id || ""
		}
	})
	if (!state.value.isHas) {
		state.value.tmsShippingId = ""
	}
}
const onSelect = (value) => {
	state.value.selectInfo = value
	loadInfo()
}
const onSearch = (value) => {
	console.log(value)
	getTmsList(value)
}
const onBlur = (value) => {
	console.log(value)
}
const close = () => {
	state.value.visible = false
}
</script>
<style lang="scss" scoped>
.ant-form {
  :deep(.ant-form-item) {
    width: 50%;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    height: 56px;
    margin-right: 0;
    padding-right: 10px;
  }
}
.ant-form {
  :deep(.ant-form-item-with-help) {
    margin-bottom: 0;
  }
}
.ant-form {
  :deep(.ant-form-item-control-input-content) {
    display: flex;
  }
}
</style>
