<template>
  <a-card>
    <template #title>
      <div class="header">
        <a-button type="primary" @click="backUp">返回</a-button>
      </div>
    </template>
    <a-form
      style="width: 540px; margin: 0 auto"
      ref="merchantRef"
      :model="ruleForm"
      :rules="rules"
      :label-col="labelCol"
      @finish="submitAdd"
      :wrapper-col="wrapperCol">
      <a-form-item label="应用图标" v-if="ruleForm.icon">
        <img
          style="width: 50px; object-fit: cover"
          :src="ruleForm.icon"
          alt="icon" />
      </a-form-item>
      <a-form-item label="应用名称" name="name">
        <a-input v-model:value="ruleForm.name" />
      </a-form-item>
      <a-form-item label="国家" name="country">
        <a-select
          ref="select"
          v-model:value="ruleForm.country"
          style="width: 100%">
          <a-select-option
            v-for="item in optionsCountry"
            :key="item.id"
            :value="item.code">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="服务列表" required v-if="subAppList.length > 0" />
      <div class="service-list flex">
        <a-card
          size="small"
          style="width: 150px"
          class="service-item"
          v-for="item in subAppList"
          :key="item.code">
          <template #title>
            <div @click="item.checked = !item.checked">
              <a-checkbox :checked="item.checked" />
              <span class="ml4">{{ item.name }}</span>
            </div>
          </template>
        </a-card>
      </div>
      <a-form-item :wrapper-col="{ span: 14, offset: 8 }">
        <a-button
          type="primary"
          style="margin: 15px auto"
          html-type="submit"
          :loading="submitLoading">
          {{ ruleForm.type === "edit" ? "修改" : "创建" }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-card>

  <a-modal
    title="应用创建"
    v-model:visible="registVisible"
    :footer="null"
    :maskClosable="false"
    :keyboard="false"
    :closable="false">
    <div class="flex-c-c flex-col">
      <p style="font-weight: bold">应用创建中……</p>
      <a-progress type="circle" :percent="percent" />
    </div>
  </a-modal>
</template>

<script>
import { onActivated, reactive, toRefs } from "vue"
import { useRouter, useRoute } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"
import { useStore } from "vuex"

export default {
	name: "CreateProduct",
	setup() {
		const router = useRouter()
		const route = useRoute()
		const store = useStore()
		const isNameHave = async (type, value) => {
			let data = {}
			const idParams = state.ruleForm.id || ""
			if (type === "name") {
				data = {
					id: state.ruleForm.type === "add" ? "" : idParams,
					name: value,
					nameType: "MERCHANT_APP",
					merchantId: store.getters["user/merchantId"],
				}
			}
			//   state.ruleForm.type === "add" ? delete data.merchantId : "";
			let exist = false
			await axios.post(api.isNameHave, data).then((res) => {
				exist = res.exist
			})
			return exist
		}
		const validateName = async () => {
			if (state.ruleForm.name === "") {
				return Promise.reject("请输入应用名称")
			}
			if (state.ruleForm.name !== "") {
				if (await isNameHave("name", state.ruleForm.name)) {
					return Promise.reject("当前名称重复，请重新输入")
				}
				return Promise.resolve()
			}
		}
		const state = reactive({
			rules: {
				name: [{ required: "true", validator: validateName, trigger: "blur" }],
				country: [{ required: "true", message: "请选择国家", trigger: "change" }],
			},
			ruleForm: {
				name: "",
				country: "",
				appCode: "",
				subAppCodeList: [],
			},
			imageUrl: "",
			loading: false,
			optionsCountry: [],
			subAppList: [],
			registVisible: false,
			submitLoading: false,
			percent: 0,
			timer: null,
		})
		onActivated(() => {
			state.ruleForm = {
				name: route.query.appName,
				country: store.getters["user/country"],
				appCode: route.query.appCode,
				subAppCodeList: [],
				icon: route.query.icon,
			}
			console.log("state.ruleForm: ", state.ruleForm)
			getCountry()
			getSubAppList()
		})
		const getCountry = () => {
			axios.post(api.countryList).then((res) => {
				state.optionsCountry = res
			})
		}
		const getSubAppList = () => {
			axios
				.post(api.getSubAppList, {
					appCode: route.query.appCode,
				})
				.then((res) => {
					state.subAppList = res
				})
		}
		const submitAdd = async () => {
			if (!state.ruleForm.name) {
				return message.warning("请输入应用名称")
			}
			state.submitLoading = true
			const data = {
				appCode: state.ruleForm.appCode,
				subAppCodeList: state.subAppList.filter((item) => item.checked).map((item) => item.code),
				productName: state.ruleForm.name,
				country: state.ruleForm.country,
				icon: state.ruleForm.icon,
			}
			try {
				await axios.post(api.createProduct, data)
				if (store.getters["user/merchantName"] === "GEEZ") {
					await axios.post(api.authoritySystemSave, {
						authoritySystem: {
							name: state.ruleForm.name,
							code: state.ruleForm.appCode,
							desc: state.ruleForm.name,
							merchantId: store.getters["user/merchantId"],
							status: 1,
						},
					})
				}
				state.registVisible = true
				state.submitLoading = false
				getRegistPercent(state.ruleForm.appCode)
			} catch {
				state.submitLoading = false
			}
		}
		const getRegistPercent = async (appCode) => {
			if (state.percent === 100) {
				clearTimeout(state.timer)
				state.timer = null
				state.registVisible = false
				router.push({ name: "applicationList" })
				return
			}
			const res = await axios.post(api.queryProductCreateProgress, {
				appCode,
			})
			state.percent = res.progress
			state.timer = setTimeout(async () => {
				getRegistPercent(appCode)
			}, 1000)
		}
		const backUp = () => {
			router.back()
		}
		const file = reactive({
			fileList: [],
			loading: false,
			imageUrl: "",
		})

		//上传功能
		const beforeUpload = (file) => {
			const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"

			if (!isJpgOrPng) {
				message.error("You can only upload JPG file!")
			}

			const isLt2M = file.size / 1024 / 1024 < 2

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!")
			}

			return isJpgOrPng && isLt2M
		}
		const getBase64 = (img, callback) => {
			const reader = new FileReader()
			reader.addEventListener("load", () => callback(reader.result))
			reader.readAsDataURL(img)
		}
		const changeUpload = (info) => {
			file.loading = true
			const formData = new FormData()
			// Get this url from response in real world.
			getBase64(info.file, (base64Url) => {
				file.imageUrl = base64Url
				file.loading = false
				formData.append("file", info.file)
				formData.append("merchantId", store.getters["user/merchantId"])
				formData.append("bizType", "APP_ICON")
				axios
					.post(api.fileUpload, formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((res) => {
						state.ruleForm.icon = res.url
					})
			})
		}
		return {
			...toRefs(state),
			...toRefs(file),
			backUp,
			submitAdd,
			labelCol: { span: 5 },
			wrapperCol: { span: 14 },
			beforeUpload,
			getBase64,
			changeUpload,
		}
	},
}
</script>
<style lang="scss" scoped>
.guest-container {
  min-height: 100%;
}

.el-card.is-always-shadow {
  min-height: 100% !important;
}

.marginInfoList {
  margin: 10px 0;
  color: #6f7071;
  font-size: 14px;

  .infoLabel {
    text-align: center;
  }

  .infoValue {
    text-align: left;
  }
}

img {
  width: 100px;
  height: 100px;
}

.boxInfo {
  display: flex;
  justify-content: space-between;
  width: 400px;

  .boxShowInfo {
    border: 1px solid #d9d9d9;
    width: 291px;
    max-height: 100px;
    overflow-y: scroll;
    padding: 6px 8px 1px 8px;

    .tagMargin {
      margin-bottom: 5px;
    }
  }
}

.service-list {
  display: flex;
  flex-wrap: wrap;

  .service-item {
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
</style>
