import { createStore } from "vuex"
import user from "./modules/user.js"
import application from "./modules/application.js"
import merchant from "./modules/merchant.js"

export default createStore({
	state: {
		appTypeListMapping: {
			PRODUCT: "产品",
			PRODUCT_SUITE: "产品套件",
			SERVICE: "服务",
		},
	},
	mutations: {},
	actions: {},
	modules: { user, application, merchant },
})
